// print

#payerInfoP {
    font-size: 16px !important;
    margin-top: 135px;
    margin-left: 150px;
    font-weight: 600;
    position: absolute;
}

#payerEinP {
    font-size: 16px !important;
    margin-top: 300px;
    margin-left: 376px;
    margin-left: 150px;
    font-weight: 600;
    position: absolute;
}

#recipientEinP {
    font-size: 16px !important;
    margin-top: 300px;
    margin-left: 376px;
    font-weight: 600;
    position: absolute;
}

#recipientNameP {
    font-size: 16px !important;
    margin-top: 382px;
    margin-left: 150px;
    font-weight: 600;
    position: absolute;
}

#recipientLine1P {
    font-size: 16px !important;
    margin-top: 457px;
    margin-left: 150px;
    font-weight: 600;
    position: absolute;
}

#recipientCityP {
    font-size: 16px !important;
    margin-top: 528px;
    margin-left: 150px;
    font-weight: 600;
    position: absolute;
}

#recipientNonEmpP {
    font-size: 16px !important;
    margin-top: 424px;
    margin-left: 640px;
    font-weight: 600;
    position: absolute;
}
#recipientNonEmpNECP{
    font-size: 16px !important;
    margin-top: 234px;
    margin-left: 630px;
    font-weight: 600;
    position: absolute;
}

//normal

#payerInfo {
    font-size: 12px !important;
    margin-top: 81px; //135px;
    margin-left: 80px; //150px;
    font-weight: 600;
    position: absolute;
}

#payerEin {
    font-size: 12px !important;
    margin-top: 175px; //300px;
    margin-left: 227px; //376px;
    margin-left: 80px;//150px;
    font-weight: 600;
    position: absolute;
}

#recipientEin {
    font-size: 12px !important;
    margin-top: 175px; //300px;
    margin-left: 227px; //376px;
    font-weight: 600;
    position: absolute;
}

#recipientName {
    font-size: 12px !important;
    margin-top: 230px; //382px;
    margin-left: 80px; //150px;
    font-weight: 600;
    position: absolute;
}

#recipientLine1 {
    font-size: 12px !important;
    margin-top: 274px; //457px;
    margin-left: 80px; //150px;
    font-weight: 600;
    position: absolute;
}

#recipientCity {
    font-size: 12px !important;
    margin-top: 316px; //528px;
    margin-left: 80px; //150px;
    font-weight: 600;
    position: absolute;
}

#recipientNonEmp {
    font-size: 12px !important;
    margin-top: 250px; //424px;
    margin-left: 386px; //640px;
    font-weight: 600;
    position: absolute;
}
#recipientNonEmpNEC{
    font-size: 12px !important;
    margin-top: 138px; //234px;
    margin-left: 380px; //630px;
    font-weight: 600;
    position: absolute;
}
