$osrit-font-sm: 14px;
$osrit-font-md: 16px;
$osrit-font-lg: 20px;
$osrit-line-height-sm: 1.5;
$osrit-line-height-md: 1.25;
$osrit-line-height-lg: 1;
$osrit-input-height-sm: 32px;
$osrit-input-height-md: 36px;
$osrit-input-height-lg: 40px;
@mixin setFontSize($size, $inputHeight, $lineHeight) {
  .nav {
    .nav-item {
      .nav-link {
        font-size: $size;
      }
    }
  }

  .navbar-nav {
    a.nav-link {
      font-size: $size;
    }
  }

  .breadcrumb {
    .breadcrumb-item {
      font-size: $size;
    }
  }

  a.btn,
  button.btn {
    font-size: $size * 0.8;
  }

  h5 {
    font-size: $size;
  }

  .list-group {
    .list-group-item {
      font-size: $size;
    }
  }

  .toast {
    .toast-header {
      font-size: $size * 1.1;
    }

    .toast-body {
      font-size: $size;
    }
  }

  input.form-control,
  input.form-control-sm,
  select.form-control,
  select.form-control-sm {
    font-size: $size * 0.9;
    height: $inputHeight;
  }
  .select__control{
    font-size: $size * 0.9;
    height: $inputHeight;
  }
  .rw-widget-input{
    font-size: $size * 0.9;
    padding: 3px;
  }

  .osrit_date_widget {
    font-size: $size * 0.83;
  }

  label.form-label-sm {
    font-size: $size * 0.9;
  }

  input.form-control-sm {
    font-size: $size;
  }

  .card {
    .card-title {
      font-size: $size;
    }
  }

  #navAllApps {
    font-size: $size;
  }

  span.badge {
    font-size: $size * 0.8;
  }

  table.table,
  table.table.table-sm,
  table.table.table-striped {
    thead {
      th {
        font-size: $size * 0.9;
      }

      tr {
        font-size: $size * 0.9;

        th {
          font-size: $size * 0.9;
        }
      }
    }

    tbody {
      tr {
        font-size: $size * 0.9;

        th {
          font-size: $size * 0.9;
        }
      }

      td {
        font-size: $size * 0.9;
        a,
        div {
          margin: 0;
          padding-top:0;
          padding-bottom: 0;
          font-size: $size * 0.9;
        }
        a.btn,
        button.btn {
          font-size: $size * 0.9;
          padding-top:0;
          padding-bottom: 0;
          margin: 0;
        }
      }
    }
  }

  a,
  div {
    font-size: $size;
  }

  dl.row {
    dd,
    dt {
      font-size: $size;
    }
  }

  .osrit_dispatch_data {
    dd,
    dt {
      font-size: $size;
    }
  }

  .osrit_more_button {
    font-size: $size * 0.8;
  }

  .modal-body,
  body,
  modal-dialog {
    font-size: $size;
  }

  .form-group {
    .osrit-inputbox-label {
      font-size: $size*2.5;
    }
  }

  .custom-switch {
    .custom-control-label {
      line-height: $lineHeight;
    }
  }

  .radio-label {
    line-height: $lineHeight*1.33;
  }

  .rw-multiselect {
    border-color: #e4e7ea;

    * {
      font-size: $size*0.9;
    }
  }
}

#osritSmall {
  @include setFontSize($osrit-font-sm, $osrit-input-height-sm, $osrit-line-height-sm);
}

#osritMedium {
  @include setFontSize($osrit-font-md, $osrit-input-height-md, $osrit-line-height-md);
}

#osritLarge {
  @include setFontSize($osrit-font-lg, $osrit-input-height-lg, $osrit-line-height-lg);
}
